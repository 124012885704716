import React, { useState, useEffect } from 'react'
import './Drones.css';
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

function ModemMainP({drone, isConfirmEmail}){
    const { t } = useTranslation(['modem']);

    const [isOnline, setIsOnline] = useState(false);

    useEffect(() => {
        return () => {
            console.log("modem main p unmount");
        };
    }, []);

    useEffect(() => {

    }, [drone]);

    function normalizeFirwareVersion(version){
        if(drone.version < 2002){
            var ver = version.toString()
            if(ver.length >= 3){
                var str1 = ver.substring(0,1)
                var str2 = ver.substring(1,2)
                var str3 = ver.substring(2,ver.length)
                return str1 + "." + str2 + "." + str3
            } else {
                return version;
            }
        }
        else 
        {
            // 00 00 00 00
            var patch = (version & 0x000000ff) >> 8 * 0;
            var minor = (version & 0x0000ff00) >> 8 * 1;
            var major = (version & 0x00ff0000) >> 8 * 2;

            return major + "." + minor + "." + patch;
        }
    }

    return(
        <>
            {drone == null ? <p>{t('modem_not_found')}</p> : 
            <>
                <p>{drone.name}</p> 
                <p>IMEI: {drone.imei}</p> 
                <p>{t('firmware_version')}: {normalizeFirwareVersion(drone.firmwareVersion)}</p>
                {isConfirmEmail ? <></> : <p>{t('email_not_confirmed')}</p>}
                {isOnline ? <p className='is_online'>Online</p> : <p className='is_offline'>Offline</p>}
            </>}
        
        </>
    )
}

export default ModemMainP;